import store from './store';
import { parseErrorMessage, unauthRes, hashString } from './utils';
import Intercom from '@intercom/messenger-js-sdk';


const domain = process.env.REACT_APP_SERVER_URL;
const userCheckUrl = 'https://api.usercheck.com/email/';
const userCheckKey = process.env.REACT_APP_USERCHECK_KEY;

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const options = (method, body, accessToken = null) => {
  let obj = {
    method,
    headers: {
      ...headers,
    },
  };
  if (accessToken) {
    obj['headers']['Authorization'] = `Bearer ${accessToken}`;
  }
  if (body) {
    obj['body'] = JSON.stringify(body);
  }
  return obj;
};

const api = {
  async signup(email, pwd1, pwd2) {
    return fetch(`${domain}/auth/signup/`, options('POST', { email, password1: pwd1, password2: pwd2 })).then(res =>
      res.json().then(r => {
        if (res.status === 400) {
          throw new Error(parseErrorMessage(r, true));
        } else {
          return r;
        }
      })
    );
  },
  async verifyEmail(key) {
    return fetch(`${domain}/auth/signup/verify-email/`, options('POST', { key })).then(res =>
      res.json().then(r => {
        if (res.status === 200) {
          return r;
        } else {
          throw new Error('This verification link has expired.');
        }
      })
    );
  },
  async resendVerificationEmail(email) {
    return fetch(`${domain}/auth/signup/resend-verification-email/`, options('POST', { email })).then(res =>
      res.json().then(r => {
        if (res.status === 200) {
          return r;
        } else {
          throw new Error('An unknown error has occurred. Please try again later.');
        }
      })
    );
  },
  async login(email, pwd) {
    return fetch(`${domain}/auth/login/`, options('POST', { email, password: pwd })).then(res =>
      res.json().then(r => {
        if (res.status === 200) {
          // console.log(r);
          store.setItem('accessToken', r['access']);
          store.setItem('refreshToken', r['refresh']);
          const hashPk = hashString(String(r['user']['pk']));
          return hashPk;
        } else {
          throw new Error(parseErrorMessage(r));
        }
      })
    );
  },
  async logout(accessToken) {
    return fetch(`${domain}/auth/logout/`, options('POST', {}, accessToken)).then(res => {
      if (res.status === 200) {
        return res.json().then(r => {
          store.clear();
          return r;
        });
      }
    });
  },
  async verifyToken(accessToken) {
    return fetch(`${domain}/auth/token/verify/`, options('POST', { token: accessToken })).then(res => {
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    });
  },
  async refreshToken(refreshToken) {
    return fetch(`${domain}/auth/token/refresh/`, options('POST', { refresh: refreshToken })).then(res => {
      if (res.status === 200) {
        return res.json().then(r => {
          store.setItem('accessToken', r['access']);
          return true;
        });
      } else {
        throw new Error('Error');
      }
    });
  },
  async forgotPasswordForm(email) {
    return fetch(`${domain}/auth/password/reset/`, options('POST', { email })).then(res => {
      if (res.status === 200) {
        return res.json().then(r => r);
      } else {
        throw new Error('Please enter a valid email address');
      }
    });
  },
  async resetPassword(uid, token, newPwd, confirmPwd) {
    return fetch(`${domain}/auth/password/reset/confirm/${uid}/${token}/`, options('POST', { uid, token, new_password1: newPwd, new_password2: confirmPwd })).then(res => {
      if (res.status === 200) {
        return res.json().then(r => r);
      } else {
        return res.json().then(r => {
          if (r.hasOwnProperty('new_password2')) {
            throw new Error(r['new_password2'].join('|'));
          } else if (r.hasOwnProperty) {
            throw new Error('This password reset link has expired.');
          } else {
            throw new Error('Something went wrong. Please click the link in our reset email and try again.');
            // LOG HERE
          }
        });
      }
    });
  },
  async listJobs(accessToken, page = 1, platform = null, dateRangeAfter = null, dateRangeBefore = null, search = null, status = null) {
    let url = `${domain}/api/jobs/?page=${page}`;
    if (platform) url += `&platform=${platform}`;
    if (dateRangeAfter) url += `&date_range_after=${dateRangeAfter}`;
    if (dateRangeBefore) url += `&date_range_before=${dateRangeBefore}`;
    if (search) url += `&search_keyword=${search}`;
    if (status) url += `&status=${status}`;
    return fetch(url, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => {
        const jobsData = r.results.map(job => ({
          'item_type': 'job',
          'job_data': job
        }));
        return { results: jobsData, count: r.count };
      });
    });
  },
  async listBatchesAndJobs(accessToken, page = 1, platform = null, dateRangeAfter = null, dateRangeBefore = null, search = null, status = null) {
    let url = `${domain}/api/jobs/list_batches_and_jobs/?page=${page}&include_job_data=true`;
    if (platform) url += `&platform=${platform}`;
    if (dateRangeAfter) url += `&date_range_after=${dateRangeAfter}`;
    if (dateRangeBefore) url += `&date_range_before=${dateRangeBefore}`;
    if (search) url += `&search_keyword=${search}`;
    if (status) url += `&status=${status}`;
    
    return fetch(url, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => r);
    });
  },
  async listGroups(accessToken) {
    return fetch(`${domain}/api/jobs/job_groups/`, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => r);
    });
  },
  async rerunGroup(accessToken, groupId) {
    return fetch(`${domain}/api/jobs/group/${groupId}/rerun/`, options('POST', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => r);
    });
  },
  async listGroupJobs(accessToken, groupId) {
    return fetch(`${domain}/api/jobs/group/${groupId}/jobs/`, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => r);
    });
  },
  async createNewJob(accessToken, servicePlatform, urls, webhookUrl) {
    return fetch(`${domain}/api/jobs/`, options('POST', { urls, service_platform: servicePlatform, webhook_url: webhookUrl }, accessToken)).then(res => {
      // console.log(res.status);
      if (res.status === 201) {
        return 1;
      } else {
        return 0;
      }
    });
  },
  async listSubscriptions(accessToken) {
    return fetch(`${domain}/api/billing/subscriptions/`, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      return res.json().then(r => r);
    });
  },
  async retrieveDashboardData(accessToken) {
    return fetch(`${domain}/auth/dashboard/data/`, options(
      'GET',
      null,
      accessToken
    )).then(res => {
      const authError = unauthRes(res);
      if (authError) throw authError;
      return res.json().then(r => r);
    });
  },
  async exportResultsEmail(accessToken, jobId) {
    return fetch(`${domain}/api/jobs/${jobId}/export/`, options('POST', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      if (res.status === 200) {
        return 'Results successfully sent via email.';
      } else {
        throw new Error('Job is not complete yet. Please wait until job completion to export its results.');
      }
    });
  },
  async createBulkReviewJobs(accessToken, servicePlatform, inputs, webhookUrl, isSearchPlatform, additionalParams = {}) {
    const baseBody = isSearchPlatform
      ? { service_platform: servicePlatform, searches: inputs }
      : { service_platform: servicePlatform, urls: inputs };

    // Filter additionalParams to include only those with valid values
    const validAdditionalParams = Object.fromEntries(
      Object.entries(additionalParams).filter(([key, value]) => value != null && value !== '' && (!Array.isArray(value) || value.length > 0))
    );

    const body = {
      ...baseBody,
      ...(webhookUrl && { webhook_url: webhookUrl }),
      ...validAdditionalParams
    };

    return fetch(`${domain}/api/jobs/bulk-create/`, options('POST', body, accessToken)).then(res => {
      if (res.status === 201) {
        return res.json().then(r => ({ success: true, message: r.detail }));
      } else {
        return res.json().then(r => ({ success: false, errors: r }));
      }
    });
  },
  async listServicePlatforms(accessToken) {
    return fetch(`${domain}/api/extractors/service-platforms/`, options('GET', null, accessToken)).then(res => {
      if (res.status === 401) {
        throw new Error(unauthRes(res));
      }
      if (res.status === 200) {
        return res.json().then(r => ({ success: true, platforms: r }));
      } else {
        return res.json().then(r => ({ success: false, message: 'Failed to retrieve service platforms' }));
      }
    });
  },
  async updateGroupName(accessToken, groupId, newName) {
    return fetch(`${domain}/api/jobs/job_groups/${groupId}/update_name/`, options('PATCH', { name: newName }, accessToken)).then(res => {
      if (res.status === 200) {
        return res.json().then(r => r);
      } else {
        return res.json().then(r => {
          const errorMessage = r.detail || (r.non_field_errors ? r.non_field_errors[0] : Object.values(r).flat()[0] || 'Failed to update group name.');
          throw new Error(errorMessage);
        });
      }
    });
  },
  async deleteGroup(accessToken, groupId) {
    return fetch(`${domain}/api/jobs/job_groups/${groupId}/`, options('DELETE', null, accessToken)).then(res => {
      if (res.status === 204) {
        return true;
      } else {
        return res.json().then(r => {
          throw new Error(r.detail || 'Failed to delete group.');
        });
      }
    });
  },
  async validateEmail(email) {
    // Check cache first
    const cachedResult = store.isEmailCached(email);
    if (cachedResult !== null) {
      if (cachedResult.disposable) {
        throw new Error('We do not accept disposable email addresses. As a small business providing a high-quality paid service, we require users to register with their regular email accounts.');
      }
      if (cachedResult.publicDomain) {
        throw new Error('Please use your business email address');
      }
      return cachedResult;
    }

    // If not in cache, make API call
    return fetch(`${userCheckUrl}${email}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userCheckKey}`
      }
    }).then(res => {
      if (res.status === 429) {
        throw new Error('We are experiencing high traffic. Please try again in a few minutes.');
      }
      return res.json().then(r => {
        if (res.status === 400) {
          throw new Error('Please enter your business email address');
        }
        
        // Cache the result
        store.setEmailValidation(email, {
          disposable: r.disposable,
          publicDomain: r.public_domain
        });
        
        if (r.disposable) {
          throw new Error('Please enter a valid business email address');
        }
        
        if (r.public_domain) {
          throw new Error('Please use your business email address');
        }
        
        return r;
      });
    });
  }
};

export default api;
