import React, { useState } from 'react';
import { format } from 'date-fns';
import EditableItemName from './EditableItemName';
import EditItemNameModal from './EditableItemNameModal';
import DeleteGroupModal from './DeleteGroupModal'; // Import the DeleteGroupModal
import NotificationToast from './NotificationToast'; // Import NotificationToast
import { TrashIcon } from '@heroicons/react/24/outline'; // Import the TrashIcon

export default function ReviewGroups({ groups, setGroups }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yy');
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState(null);

  // Add state for success notification
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleNameUpdate = (groupId, newName) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === groupId ? { ...group, name: newName } : group
      )
    );
  };

  const renderGroupRow = (group) => {
    return (
      <tr key={group.id} className="bg-white group">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <EditableItemName
            name={group.name}
            onEdit={() => {
              setSelectedGroup(group);
              setIsEditModalOpen(true);
            }}
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {formatDate(group.created)}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {group.last_run ? formatDate(group.last_run) : '-'}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {group.scheduled_interval || '-'}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {group.next_run ? formatDate(group.next_run) : '-'}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
          <button
            className="text-gray-500 hover:text-red-600"
            onClick={() => {
              setSelectedGroupToDelete(group);
              setIsDeleteModalOpen(true);
            }}
          >
            <TrashIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Run
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Scheduled Interval
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Next Run
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {groups.map(renderGroupRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <EditItemNameModal
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        item={selectedGroup}
        itemType="group"
        onNameUpdate={(newName) => {
          handleNameUpdate(selectedGroup.id, newName);
          setSelectedGroup(null);
        }}
      />
      <DeleteGroupModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        group={selectedGroupToDelete}
        onDelete={() => {
          setGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== selectedGroupToDelete.id)
          );
          setSelectedGroupToDelete(null);
        }}
        setSuccessMessage={setSuccessMessage}
        setShowSuccessToast={setShowSuccessToast}
      />
      {/* Render the NotificationToast */}
      <NotificationToast
        show={showSuccessToast}
        setShow={setShowSuccessToast}
        notifMsg={successMessage}
        notifType="success"
      />
    </div>
  );
}