import { useEffect, useState } from 'react';
import Skeleton from '../components/Skeleton';
import Plans from '../components/Plans';
import Subscription from '../components/Subscription';
import api from '../utils/api';
import store from '../utils/store';
import LoadingPage from './LoadingPage';
import analytics from '../utils/analytics';
import NotificationToast from '../components/NotificationToast';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useSearchParams } from 'react-router-dom';

export default function Billing() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuccess, setShowSuccess] = useState(false);

  const accessToken = store.getItem('accessToken');

  async function apiCalls() {
    if (!accessToken) {
      setErrorMsg('No access token found');
      setShowErrorToast(true);
      setIsLoading(false);
      return;
    }

    try {
      const dashboard = await api.retrieveDashboardData(accessToken);
      setDashboard(dashboard);

      const s = await api.listSubscriptions(accessToken);
      analytics.track('View billing');
      if (s.length >= 1) {
        setSubscription(s[0]);
      }
    } catch (e) {
      setErrorMsg(e.message);
      setShowErrorToast(true);
      setDashboard(null);
    } finally {
      setIsLoading(false);
    }
  }
  
  useEffect(() => {
    apiCalls();
  }, []);

  useEffect(() => {
    if (searchParams.get('success') === 'true' && store.shouldShowPurchaseMessage()) {
      setShowSuccess(true);
      store.markPurchaseMessageShown();
      // Remove success param from URL without page refresh
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('success');
      setSearchParams(newParams);
    }
  }, [searchParams, setSearchParams]);

  const SuccessMessage = () => (
    <div className="bg-white max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <div className="mx-auto h-24 w-24 text-green-500 mb-8">
          <CheckBadgeIcon />
        </div>
        <h1 className="text-4xl text-gray-900 mb-4">
          Congratulations! Your purchase was successful 🎉
        </h1>
        <p className="text-xl text-gray-500 max-w-2xl mx-auto">
          You're on the fastrack for accessing real world ecommerce data for your business. Please refresh page to continue scraping 🤓
        </p>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Skeleton dashboard={dashboard}>
      {errorMsg && (
        <NotificationToast 
          show={showErrorToast} 
          setShow={setShowErrorToast}
          notifMsg={errorMsg}
          notifType="error"
        />
      )}
      {showSuccess ? (
        <SuccessMessage />
      ) : dashboard && subscription ? (
        <Subscription subscription={subscription} />
      ) : dashboard ? (
        <Plans />
      ) : null}
    </Skeleton>
  );
}
