import React, { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { PlayIcon, PauseIcon, ClipboardDocumentIcon, ClipboardDocumentListIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';

const domain = process.env.REACT_APP_SERVER_URL;

const platformOptions = {
    'amazon': 'Amazon',
    'walmart': 'Walmart', 
    'bestbuy': 'Best Buy',
    'costco': 'Costco',
    'homedepot': 'The Home Depot',
    'wayfair': 'Wayfair',
    'lowes': 'Lowes.com',
    'samsclub': 'Sam\'s Club',
    'yelp': 'Yelp',
    'target': 'Target.com',
    'mercado': 'Mercado Livre Brazil',
    'build': 'Build.com',
    'mediamarkt': 'MediaMarkt',
    'currys': 'Currys',
    'bedbathbeyond': 'Bed, Bath & Beyond',
}

const typeOptions = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'reviews': 'Reviews'
}

const typeOptionsHomeDepot = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'reviews': 'Reviews'
}

const typeOptionsWayfair = {
    'detail': 'Product Page',
}

const typeOptionsCostco = {
    'search': 'Search Results',
    'reviews': 'Reviews',
    'category': 'Category'
}

const typeOptionsMercado = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'sellers': 'Sellers / Offers'
}

const typeOptionsYelp = {
  'search': 'Search Results',
  'reviews': 'Reviews'
}

const typeOptionsBuild = {
  'search': 'Search Results',
  'detail': 'Product Page',
};

const typeOptionsWalmart = {
  'search': 'Search Results',
  'detail': 'Product Page',
  'reviews': 'Reviews'  
};

const typeOptionsMediaMarkt = {
    'reviews': 'Reviews'
}

const typeOptionsCurrys = {
    'reviews': 'Reviews'
}

const typeOptionsBedbathbeyond = {
    'detail': 'Product Page',
}

const countryOptions = {
  'us': 'USA',
  'ca': 'Canada',
  'gb': 'UK',
  'fr': 'France',
  'de': 'Germany',
  'es': 'Spain',
  'jp': 'Japan',
  'au': 'Australia',
  'in': 'India',
  'br': 'Brazil',
};

const mediaMarktCountries = {
    'de': 'Germany',
    'nl': 'Netherlands',
    'ch': 'Switzerland',
    'es': 'Spain',
    'pl': 'Poland',
    'be': 'Belgium',
    'at': 'Austria'
}

const typeOptionsAmazon = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'reviews': 'Reviews',
    'category': 'Category'
}

function ensureSingleEncoding(url) {
  try {
    // First try to decode the URL to see if it's already encoded
    const decodedUrl = decodeURIComponent(url);
    // If decoding succeeds, encode once
    return encodeURIComponent(decodedUrl);
  } catch (e) {
    // If decoding fails (URL wasn't encoded), just encode once
    return encodeURIComponent(url);
  }
}

export default function PlaygroundForm({apiKey, refreshCredits}) {
  const [errors, setErrors] = useState({});
  const [platform, setPlatform] = useState('amazon');
  const [type, setType] = useState('search');
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('San Francisco, CA');
  const [country, setCountry] = useState('us');
  const [page, setPage] = useState(1);
  const [productURL, setProductURL] = useState('');
  const [itemId, setItemId] = useState('');
  const [bizId, setBizId] = useState('');
  const [asin, setAsin] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [requestURL, setRequestURL] = useState('');
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [response, setResponse] = useState('');
  const [showCheckIconRequest, setShowCheckIconRequest] = useState(false);
  const [showCheckIconResponse, setShowCheckIconResponse] = useState(false);
  const [cookie, setCookie] = useState('');

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  }

  const handleTypeChange = (event) => {
    setType(event.target.value);
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }

  const handleProductURLChange = (event) => {
    try {
        const url = new URL(event.target.value);
        if (type !== 'category') {
            setProductURL(url.origin + url.pathname);
        } else {
            setProductURL(event.target.value);
        }
        setErrors(prev => ({ ...prev, productURL: null }));
    } catch (error) {
        setProductURL(event.target.value);
        setErrors(prev => ({ ...prev, productURL: 'Invalid URL' }));
    }
  }

  const handleItemIdChange = (event) => {
    setItemId(event.target.value);
  }

  const handleBizIdChange = (event) => {
    setBizId(event.target.value);
  }

  const handleAsinChange = (event) => {
    setAsin(event.target.value);
  }

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  }

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  }

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  }

  const handlePageChange = (event) => {
    setPage(event.target.value);
  }

  const handleCookieChange = (event) => {
    setCookie(event.target.value);
  }

  const getTypeOptions = (platform) => {
    switch(platform) {
      case 'amazon':
        return typeOptionsAmazon;
      case 'homedepot':
        return typeOptionsHomeDepot;
      case 'mercado':
        return typeOptionsMercado;
      case 'wayfair':
        return typeOptionsWayfair;
      case 'bedbathbeyond':
        return typeOptionsBedbathbeyond;
      case 'costco':
        return typeOptionsCostco;
      case 'yelp':
        return typeOptionsYelp;
      case 'build':
        return typeOptionsBuild;
      case 'walmart':
        return typeOptionsWalmart;
      case 'mediamarkt':
        return typeOptionsMediaMarkt;
      case 'currys':
        return typeOptionsCurrys;
      default:
        return typeOptions;
    }
  }

  const genRequestURL = (platform, type, keyword, productURL, country, location, page, itemId, bizId, asin, countryCode, apiKey, cookie) => {
    if (type === 'search') {
      if (platform === 'yelp') {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${ensureSingleEncoding(keyword)}&location=${location}&page=${page}&api_key=${apiKey}`;
      } else if (platform === 'amazon') {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${ensureSingleEncoding(keyword)}&country_code=${country}&page=${page}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${ensureSingleEncoding(keyword)}&page=${page}&api_key=${apiKey}`;
      }
    } else if (type === 'reviews') {
      if (platform === 'yelp') {
        return `${domain}/api/getter/?platform=${platform}_${type}&biz_id=${bizId}&page=${page}&api_key=${apiKey}`;
      } else if (platform === 'amazon') {
        if (productURL) {
          return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&page=${page}&cookie=${encodeURIComponent(cookie)}&api_key=${apiKey}`;
        } else {
          return `${domain}/api/getter/?platform=${platform}_${type}&asin=${asin}&country_code=${countryCode}&page=${page}&cookie=${encodeURIComponent(cookie)}&api_key=${apiKey}`;
        }
      } else if (platform === 'mediamarkt') {
        return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&page=${page}&api_key=${apiKey}`;
      } else if (platform === 'homedepot') {
        return `${domain}/api/getter/?platform=${platform}_${type}&${itemId ? `item_id=${itemId}` : `url=${ensureSingleEncoding(productURL)}`}&page=${page}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&page=${page}&api_key=${apiKey}`;
      }
    } else if (type === 'category') {
      return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&page=${page}&api_key=${apiKey}`;
    } else if (type === 'sellers') {
      return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&page=${page}&api_key=${apiKey}`;
    } else if (type === 'detail') {
      if (platform === 'amazon') {
        if (productURL) {
          return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&api_key=${apiKey}`;
        } else {
          return `${domain}/api/getter/?platform=${platform}_${type}&asin=${asin}&country_code=${countryCode}&api_key=${apiKey}`;
        }
      } else if (platform === 'homedepot' || platform === 'bestbuy') {
        return `${domain}/api/getter/?platform=${platform}_${type}&${itemId ? `item_id=${itemId}` : `url=${ensureSingleEncoding(productURL)}`}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&url=${ensureSingleEncoding(productURL)}&api_key=${apiKey}`;
      }
    }
  }

  useEffect(() => {
    setRequestURL(genRequestURL(platform, type, keyword, productURL, country, location, page, itemId, bizId, asin, countryCode, apiKey, cookie));
  }, [platform, type, keyword, productURL, page, country, location, itemId, bizId, asin, countryCode, apiKey, cookie])

  useEffect(() => {
    if (platform === 'wayfair' || platform === 'bedbathbeyond') {
        setType('detail');
    } else if (platform === 'mediamarkt' || platform === 'currys') {
        setType('reviews');  // Set default type to 'reviews' for MediaMarkt and Currys
    } else {
        setType('search'); // Default type for all other platforms
    }
    setKeyword('');
    setLocation('San Francisco, CA');
    setCountry('us');
    setPage(1);
    setProductURL('');
    setItemId('');
    setBizId('');
    setAsin('');
    setCountryCode('us');
    setErrors({});
  }, [platform]);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (type === 'search') {
      if (!keyword.trim()) {
        newErrors.keyword = 'Keyword is required.';
        isValid = false;
      }
    } else if (type === 'detail' || type === 'reviews' || type === 'sellers') {
      if (platform === 'amazon') {
        if (!productURL && !asin) {
          newErrors.productURL = 'Product URL or ASIN is required.';
          isValid = false;
        }

        if (productURL) {
          try {
            const url = new URL(productURL);
            if (!url.hostname.includes('amazon.')) {
              newErrors.productURL = 'URL must be an Amazon URL.';
              isValid = false;
            }
          } catch {
            newErrors.productURL = 'Invalid URL.';
            isValid = false;
          }
        }
      } else if (platform === 'homedepot') {
        if (!productURL && !itemId) {
          newErrors.productURL = 'Product URL or Item ID is required.';
          isValid = false;
        }

        if (productURL) {
          try {
            const url = new URL(productURL);
            if (!url.hostname.includes('homedepot.')) {
              newErrors.productURL = 'URL must be a Home Depot URL.';
              isValid = false;
            }
          } catch {
            newErrors.productURL = 'Invalid URL.';
            isValid = false;
          }
        }
      } else if (platform === 'bestbuy' && type === 'detail') {
        if (!productURL && !itemId) {
          newErrors.productURL = 'Product URL or Item ID is required.';
          isValid = false;
        }
      } else {
        if (!productURL) {
          newErrors.productURL = 'Product URL is required.';
          isValid = false;
        } else {
          try {
            const url = new URL(productURL);
            // Set expected hostnames or keywords per platform
            const platformHosts = {
              walmart: 'walmart.',
              bestbuy: 'bestbuy.',
              costco: 'costco.',
              wayfair: 'wayfair.',
              lowes: 'lowes.',
              samsclub: 'samsclub.',
              target: 'target.',
              build: 'build.',
              // Add other platforms as needed
            };
            const expectedHost = platformHosts[platform];
            if (expectedHost && !url.hostname.includes(expectedHost)) {
              newErrors.productURL = `URL must be a ${platformOptions[platform]} URL.`;
              isValid = false;
            }
          } catch {
            newErrors.productURL = 'Invalid URL.';
            isValid = false;
          }
        }
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Disable the button immediately
    setIsLoadingRequest(true);
    setResponse('');
    try {
      const res = await fetch(requestURL).then(res => res.json());
      setResponse(res);
    } catch (error) {
      console.error(error);
      // Optionally handle errors and set an error state to display an error message
    } finally {
      setIsLoadingRequest(false);
      refreshCredits();
    }
  }

  const renderCookieInput = () => {
    if (platform === 'amazon' && type === 'reviews') {
      return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="cookie" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
            Cookie
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              id="cookie"
              name="cookie"
              type="text"
              value={cookie}
              onChange={handleCookieChange}
              className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <p className="mt-2 text-sm text-gray-500 pr-10">
              Amazon has blocked access to the reviews page for users who are not logged-in. Because of this we need your cookie to scrape reviews from Amazon. <a className='text-mypurple hover:text-myorange' href="https://help.unwrangle.com/en/articles/10143291-how-to-get-the-cookie-string-for-your-amazon-account" target="_blank">Here's</a> a step by step guide you can follow to get the cookie for your account.
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <h3 className="section-heading">API Playground</h3>
      <div className='px-4 py-5 bg-white shadow rounded-lg my-3 flex flex-wrap'>
        <div className='flex-1 p-2 sm:border-r-2 w-full lg:w-1/2 mb-4 lg:mb-0'>
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Select an API</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Make test requests to any of our APIs with the form below</p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  {/* Platform selection */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="platform" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                      Select Platform
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select 
                        id="platform"
                        name="platform" 
                        value={platform} 
                        onChange={handlePlatformChange} 
                        className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {Object.keys(platformOptions).map((option, i) => (<option value={option} key={i}>{platformOptions[option]}</option>))}
                      </select>
                    </div>
                  </div>

                  {/* Data Type selection */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="dataType" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                      Select Data Type
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select 
                        id="dataType"
                        name="dataType" 
                        value={type} 
                        onChange={handleTypeChange} 
                        className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {Object.keys(getTypeOptions(platform)).map((option, i) => (<option value={option} key={i}>{getTypeOptions(platform)[option]}</option>))}
                      </select>
                      {platform === 'amazon' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Please note that sponsored listings' urls will not work with the Amazon Product Data API.  
                        </p>
                      )}
                      {platform === 'amazon' && type === 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use API with cookie to get reviews up to page 10 and without cookie to get 8 most recent reviews.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'search' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-search-scraper" target="_blank">Mercado Livre Search Scraper</a> to get all search results for a keyword with a single request.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'sellers' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-price-offers-scraper" target="_blank">Mercado Livre Price Offers Scraper</a> to get all seller / price offers for a listing.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-product-scraper" target="_blank">Mercado Livre Product Scraper</a> to get product details for multiple listings at once.
                        </p>
                      )}
                      {platform === 'wayfair' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/wayfair-product-scraper" target="_blank">Wayfair Product Scraper</a> if you also want to get specs and dimensions for each product.
                        </p>
                      )}
                      {platform === 'yelp' && type === 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/yelp-reviews-scraper" target="_blank">Yelp Reviews Scraper</a> to get all the reviews for a business with a single request.
                        </p>
                      )}
                      {platform == 'homedepot' && type == 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/homedepot-reviews-scraper" target="_blank">Home Depot Reviews Scraper</a> to get all reviews for a product at once.
                        </p>
                      )}
                      {platform === 'mediamarkt' && type === 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Supported MediaMarkt domains: {Object.values(mediaMarktCountries).join(', ')}.
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Search Keyword input */}
                  {type === 'search' && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="keyword" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Search Keyword
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="keyword"
                          name="keyword"
                          type="text"
                          value={keyword}
                          autoComplete="keyword"
                          onChange={handleKeywordChange}
                          className={`block w-full sm:w-60 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${errors.keyword ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.keyword && (
                          <p className="mt-2 text-sm text-red-500">{errors.keyword}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Product URL or Item ID input */}
                  {(type === 'detail' || type === 'reviews' || type === 'sellers' || type === 'category') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="productURL" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        {platform === 'yelp' && type === 'reviews' ? 'Biz ID' : 
                         type === 'category' ? 'Category URL' : 'Product URL'}
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        {platform === 'yelp' && type === 'reviews' ? (
                          <input
                            id="bizId"
                            name="bizId"
                            type="text"
                            value={bizId}
                            autoComplete="bizId"
                            onChange={handleBizIdChange}
                            className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        ) : (
                          <input
                            id="productURL"
                            name="productURL"
                            type="text"
                            value={productURL}
                            autoComplete="productURL"
                            onChange={handleProductURLChange}
                            className={`block w-full sm:w-60 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${errors.productURL ? 'border-red-500' : 'border-gray-300'}`}
                          />
                        )}
                        {errors.productURL && (
                          <p className="mt-2 text-sm text-red-500">{errors.productURL}</p>
                        )}
                        <p className="mt-2 text-sm text-gray-500 pr-10">
                          {type === 'category' 
                              ? "Note: Use any department or category URL with pagination here to scrape results."
                              : "Note: please ensure that product URL does not contain any query parameters."
                          }
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Item ID input for Home Depot and Best Buy */}
                  {((platform === 'homedepot' && (type === 'detail' || type === 'reviews')) || 
                    (platform === 'bestbuy' && type === 'detail')) && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="itemId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Item ID (optional)
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="itemId"
                          name="itemId"
                          type="text"
                          value={itemId}
                          autoComplete="itemId"
                          onChange={handleItemIdChange}
                          className={`block w-full sm:w-60 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${errors.itemId ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {errors.itemId && (
                          <p className="mt-2 text-sm text-red-500">{errors.itemId}</p>
                        )}
                        <p className="mt-2 text-sm text-gray-500 pr-10">
                          Note: You can provide either URL or Item ID for {platformOptions[platform]} {type === 'detail' ? 'product details' : 'details and reviews'}.
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Location input for Yelp */}
                  {(platform === 'yelp' && type === 'search') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Location
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="location"
                          name="location"
                          type="text"
                          value={location}
                          autoComplete="location"
                          onChange={handleLocationChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {/* Country selection for Amazon */}
                  {(platform === 'amazon' && type === 'search') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Country
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select 
                          id="country"
                          name="country" 
                          value={country} 
                          onChange={handleCountryChange} 
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          {Object.keys(countryOptions).map((option, i) => (<option value={option} key={i}>{countryOptions[option]}</option>))}
                        </select>
                      </div>
                    </div>
                  )}

                  {(type === 'search' || type === 'reviews' || type === 'sellers' || type === 'category') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="page" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Page
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="page"
                          name="page"
                          type="number"
                          min="1"
                          value={page}
                          autoComplete="page"
                          onChange={handlePageChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {/* ASIN and Country Code inputs for Amazon detail and reviews */}
                  {(platform === 'amazon' && (type === 'detail' || type === 'reviews')) && (
                    <>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="asin" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                          ASIN
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <input
                            id="asin"
                            name="asin"
                            type="text"
                            value={asin}
                            autoComplete="asin"
                            onChange={handleAsinChange}
                            className={`block w-full sm:w-60 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${errors.asin ? 'border-red-500' : 'border-gray-300'}`}
                          />
                          {errors.asin && (
                            <p className="mt-2 text-sm text-red-500">{errors.asin}</p>
                          )}
                          <p className="mt-2 text-sm text-gray-500 pr-10">
                            Note: You can provide either Product URL or ASIN + Country Code for Amazon {type === 'detail' ? 'detail' : 'reviews'}.
                          </p>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                          Country Code
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            id="countryCode"
                            name="countryCode"
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            {Object.keys(countryOptions).map((option, i) => (
                              <option value={option} key={i}>
                                {countryOptions[option]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Cookie input for Amazon reviews */}
                  {platform === 'amazon' && type === 'reviews' && renderCookieInput()}
                </div>
              </div>
              <p className="pt-3 text-sm text-gray-500 whitespace-normal break-words">
                Requesting, <span className="mono text-mypurple">{requestURL.length > 500 ? requestURL.slice(0,500) + '...' : requestURL}</span>
              </p>
            </div>

            <div className="pt-5">
              <div className="flex">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-mypurple py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                  disabled={isLoadingRequest}
                >
                  <p className='sm:mt-0.5'>Send Request </p>
                  {isLoadingRequest ? (
                    <PauseIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <PlayIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:bg-mypurple"
                  onClick={() => {
                    navigator.clipboard.writeText(requestURL);
                    setShowCheckIconRequest(true);
                    setTimeout(() => setShowCheckIconRequest(false), 5000);
                  }}
                >
                  <p className='sm:mt-0.5'>Copy Request</p>
                  {showCheckIconRequest ? (
                    <CheckIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <ClipboardDocumentIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:bg-mypurple"
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(response, null, 2));
                    setShowCheckIconResponse(true);
                    setTimeout(() => setShowCheckIconResponse(false), 5000);
                  }}
                >
                  <p className='sm:mt-0.5'>Copy Response</p>
                  {showCheckIconResponse ? (
                    <CheckIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <ClipboardDocumentListIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='flex-1 p-2 w-full lg:w-1/2'>
          <div className='bg-black shadow rounded-lg h-full max-w-full sm:max-w-2xl max-h-screen p-2 overflow-x-auto overflow-y-auto min-h-[300px]'>
            {isLoadingRequest && <p className='text-white font-medium'>Loading...</p>}
            {response && (
              <div className='p-2'>
                <SyntaxHighlighter 
                  language="json" 
                  style={anOldHope}
                >
                  {JSON.stringify(response, null, 2)}
                </SyntaxHighlighter>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}