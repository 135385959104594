import React from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js';
import App from './App';
// import "react-day-picker/dist/style.css" // Import the CSS for react-day-picker
// import '@radix-ui/themes/styles.css';
import './global.css'
import reportWebVitals from './reportWebVitals';

import "@fontsource/inter";

// Initialize PostHog
const POSTHOG_TOKEN = process.env.REACT_APP_POSTHOG_TOKEN;

if (!POSTHOG_TOKEN) {
  console.warn('PostHog token not found in environment variables');
}

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init(POSTHOG_TOKEN, { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();