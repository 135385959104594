import { useEffect, useState, useCallback } from 'react';
import PlaygroundForm from '../components/PlaygroundForm';
import HomeHero from '../components/HomeHero';
import Skeleton from '../components/Skeleton';
import api from '../utils/api';
import store from '../utils/store';
import analytics from '../utils/analytics';
import LoadingPage from './LoadingPage';
import NotificationToast from '../components/NotificationToast'; // Import the NotificationToast component

import './Home.css';

export default function Home() {
  const [dashboard, setDashboard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestCounter, setRequestCounter] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorToast, setShowErrorToast] = useState(false); // Add state for showing/hiding the error toast

  const getAccessToken = useCallback(() => store.getItem('accessToken'), []);

  const handleRequestRefresh = useCallback(() => {
    setRequestCounter(prevCounter => prevCounter + 1);
  }, []);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const accessToken = getAccessToken();
        const identifier = store.getItem('identifier');
        
        if (!accessToken) {
          setErrorMsg('No access token found');
          setShowErrorToast(true);
          setIsLoading(false);
          return;
        }
        const dashboard = await api.retrieveDashboardData(accessToken);
        setDashboard(dashboard);

        analytics.identify(identifier, dashboard?.email);
        analytics.track('View Home', {
          'credits': dashboard?.credits.amt
        });

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setErrorMsg(error.message);
        setShowErrorToast(true);
        setDashboard(null);  // Ensure dashboard is null on error
      } finally {
        setIsLoading(false);
      }
    };

    getDashboardData();
  }, [requestCounter, getAccessToken]);

  useEffect(() => {
    return function cleanup() {
      setDashboard(null);
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!dashboard) {
    return (
      <Skeleton dashboard={null}>
        {errorMsg && (
          <NotificationToast
            show={showErrorToast}
            setShow={setShowErrorToast}
            notifMsg={errorMsg}
            notifType="error"
          />
        )}
      </Skeleton>
    );
  }

  return (
    <Skeleton dashboard={dashboard}>
      <HomeHero dashboard={dashboard} />
      <PlaygroundForm refreshCredits={handleRequestRefresh} apiKey={dashboard?.token} />
      {errorMsg && (
        <NotificationToast
          show={showErrorToast}
          setShow={setShowErrorToast}
          notifMsg={errorMsg}
          notifType="error"
        />
      )}
    </Skeleton>
  );
}
