/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid'

import api from '../utils/api';
import store from '../utils/store';
import analytics from '../utils/analytics';


const tiers = [
  {
    name: 'Starter',
    href: '#',
    priceMonthly: process.env.REACT_APP_STARTER,
    description: 'Perfect for developers starting out with web data extraction.',
    includedFeatures: [
      '100,000 credits / month',
      '50 concurrent requests',
      'Shared Queue',
      'Email support'
    ],
    productId: 649292
    },
    {
      name: 'Standard',
      href: '#',
      priceMonthly: process.env.REACT_APP_STANDARD,
      description: 'A plan for businesses looking to extract data at scale',
      includedFeatures: [
        '300,000 credits / month',
        '100 concurrent requests',
        'Shared Queue',
        'Priority Email support'
      ],
      productId: 649293
    },
    {
      name: 'Professional',
      href: '#',
      priceMonthly: process.env.REACT_APP_PROFESSIONAL,
      description: 'For businesses looking to supercharge thier research activities',
      includedFeatures: [
        '750,000 credits / month',
        '200 concurrent requests',
        'Shared Queue',
        'Priority Email support',
        'Team Management'
      ],
      productId: 654238
    },
    {
      name: 'Enterprise',
      href: '#',
      priceMonthly: process.env.REACT_APP_ENTERPRISE,
      description: 'A plan for enterprises looking to extract data at scale',
      includedFeatures: [
        '2,000,000 credits / month',
        '350 concurrent requests',
        'Dedicated Queue',
        'Priority Email support',
        'Team Management',
        'Dedicated Account Manager'
      ],
      productId: 654239
    }
  ]
  // {
  //   name: 'Data Feed - Weekly',
  //   href: '#',
  //   priceMonthly: process.env.REACT_APP_DATAFEED_WEEKLY,
  //   description: 'Receive CSV files weekly directly in your inbox',
  //   includedFeatures: [
  //     'Weekly updated CSV feed',
  //     'Single product category',
  //     'Direct data delivered in your inbox',
  //     'Email support'
  //   ],
  //   productId: 738604
  // }

export default function Plans() {
  const [email, setEmail] = useState('')

  const getDashboardData = async () => {
    const accessToken = store.getItem('accessToken');
    const dashboard = await api.retrieveDashboardData(accessToken);
    setEmail(dashboard['email']);
  };

  useEffect(() => {
    getDashboardData()
  }, [])


  const Paddle = window.Paddle;

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Monthly subscription plans available for purchase.
          </p>
        </div>
        {/* <div className="bg-gray-50 sm:rounded-lg mt-8">
          <div className="px-4 py-5 sm:p-5 flex justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Pay as you use</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Purchase 10,000 non-expiring credits for $10.00</p>
              </div>
            </div>
            <div className="mt-5">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                disabled={email ? false : true}
                onClick={(e) => {
                  // const Paddle = window.Paddle;
                  e.preventDefault();
                  Paddle.Checkout.open({
                    product: 649183,
                    email,
                    success: 'https://console.unwrangle.com/'
                  });
                  analytics.track('Click buy credits');
                }}
              >
                Buy Credits
              </button>
            </div>
          </div>
        </div> */}
        <div className="mt-12 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
                  <span className="text-base font-medium text-gray-500">/mo</span>
                </p>
                <button
                  href={tier.href}
                  disabled={email ? false : true}
                  onClick={(e) => {
                    // const Paddle = window.Paddle;
                    e.preventDefault();
                    Paddle.Checkout.open({
                      product: tier.productId,
                      email,
                      success: 'https://console.unwrangle.com/billing?success=true'
                    });
                    analytics.track('Click subscribe', {
                      'plan': tier.name
                    })
                  }}
                  className="mt-8 block w-full bg-mypurple border border-myviolet rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Buy {tier.name}
                </button>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
