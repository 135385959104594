import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

// Warn if token is missing
if (!MIXPANEL_TOKEN) {
  console.warn('Mixpanel token not found in environment variables');
}

mixpanel.init(MIXPANEL_TOKEN);

const env_check = process.env.NODE_ENV === 'production';

const analytics = {
  identify: (id, email) => {
    if (env_check && id) {
      try {
        mixpanel.identify(id);
        mixpanel.people.set({'$email': email});
        posthog.identify(id, { email: email });
      } catch (error) {
        console.error('Analytics identify failed:', error);
      }
    }
  },
  alias: (id) => {
    if (env_check) {
      try {
        mixpanel.alias(id);
      } catch (error) {
        console.error('Analytics alias failed:', error);
      }
    }
  },
  track: (name, props) => {
    if (env_check) {
      try {
        mixpanel.track(name, props);
        posthog.capture(name.toLowerCase().replace(/ /g, '_'), props);
      } catch (error) {
        console.error('Analytics track failed:', error);
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        try {
          mixpanel.people.set(props);
          posthog.capture('$set', {$set: props});
        } catch (error) {
          console.error('Analytics people.set failed:', error);
        }
      }
    },
  },
};

export default analytics;
