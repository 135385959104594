import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

export default function EditableItemName({ name, onEdit }) {
  return (
    <div className="flex items-center group">
      <span
        className="cursor-pointer group-hover:underline group-hover:decoration-dashed group-hover:text-mypurple"
        onClick={onEdit}
      >
        {name}
      </span>
      <button
        className="ml-1 text-gray-500 opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={onEdit}
      >
        <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  );
}
