import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogPanel, DialogBackdrop } from '@headlessui/react';
import { DatePickerWithRange } from './shadcnui/DatePickerWithRange';
import api from '../utils/api'; // Import the API module
import store from '../utils/store'; // Import the store module

function FiltersModal({ open, setOpen, platforms, selectedPlatform, setSelectedPlatform, selectedDateRange, setSelectedDateRange, selectedStatus, setSelectedStatus }) {
  const [unappliedPlatform, setUnappliedPlatform] = useState(null);
  const [unappliedDateRange, setUnappliedDateRange] = useState({
    from: null,
    to: null,
  });
  const [unappliedStatus, setUnappliedStatus] = useState(null);

  useEffect(() => {
    return () => {
      setUnappliedPlatform(null);
      setUnappliedDateRange({
        from: null,
        to: null,
      });
    };
  }, []);

  // Group platforms
  const groupPlatforms = () => {
    let groups = {};
    platforms.forEach(platform => {
      const [platformName] = platform.name.split('_');
      if (!groups[platformName]) {
        groups[platformName] = [];
      }
      groups[platformName].push(platform);
    });

    // Sort groups alphabetically and platforms within groups in reverse alphabetical order
    return Object.keys(groups).sort().reduce((acc, key) => {
      acc[key] = groups[key].sort((a, b) => b.name.localeCompare(a.name));
      return acc;
    }, {});
  };

  const groupedPlatforms = groupPlatforms();

  const statusOptions = [
    { value: '', label: 'All Statuses' },
    { value: 'queued', label: 'Queued' },
    { value: 'complete', label: 'Complete' },
    { value: 'invalid_url', label: 'Invalid URL' },
    { value: 'maintenance', label: 'Maintenance' },
  ];

  function handleApplyFilters() {
    setOpen(false);
    setSelectedPlatform(unappliedPlatform);
    setSelectedDateRange(unappliedDateRange);
    setSelectedStatus(unappliedStatus);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0 mt-5">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Filters
              </DialogTitle>
              <div className="my-4">
                <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
                  Select Platform
                </label>
                <select
                  id="platform"
                  name="platform"
                  value={unappliedPlatform || selectedPlatform || ''}
                  onChange={(e) => setUnappliedPlatform(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="">All Platforms</option>
                  {Object.entries(groupedPlatforms).map(([groupName, groupPlatforms]) => (
                    <React.Fragment key={groupName}>
                      <option disabled className="font-bold bg-gray-100">
                        {groupName.charAt(0).toUpperCase() + groupName.slice(1)} Scrapers
                      </option>
                      {groupPlatforms.map(platform => (
                        <option key={platform.name} value={platform.name}>
                          {platform.name.replace('_', ' ')}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </select>
              </div>
              <DatePickerWithRange  
                label="Date Range"
                value={unappliedDateRange.from !== null || selectedDateRange}
                onChange={setUnappliedDateRange}
              />
              <div className="my-4">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Select Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={unappliedStatus || selectedStatus || ''}
                  onChange={(e) => setUnappliedStatus(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {statusOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleApplyFilters}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-mypurple px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Apply Filters
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default FiltersModal;