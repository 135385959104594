import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogPanel, DialogBackdrop } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import api from '../utils/api';
import store from '../utils/store';

export default function DeleteGroupModal({ open, setOpen, group, onDelete, setSuccessMessage, setShowSuccessToast }) {
  const [errorMsg, setErrorMsg] = useState('');
  const [groupNameInput, setGroupNameInput] = useState('');

  if (!group) {
    return null;
  }

  const handleDelete = async () => {
    if (groupNameInput !== group.name) {
      setErrorMsg('Group name does not match.');
      return;
    }
    try {
      const accessToken = store.getItem('accessToken');
      await api.deleteGroup(accessToken, group.id);

      // Set success message and show toast
      setSuccessMessage(`Group "${group.name}" deleted successfully.`);
      setShowSuccessToast(true);

      onDelete();

      setOpen(false);

    } catch (error) {
      setErrorMsg(error.message || 'Failed to delete group.');
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Delete Group
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete the group "{group.name}"? This action cannot be undone.
                        Please type the group name to confirm.
                      </p>
                      <input
                        type="text"
                        placeholder={group.name}
                        className="mt-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm"
                        value={groupNameInput}
                        onChange={(e) => {
                          setGroupNameInput(e.target.value);
                          setErrorMsg(''); // Clear error when user types
                        }}
                      />
                      {errorMsg && (
                        <p className="mt-2 text-sm text-red-600">{errorMsg}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}