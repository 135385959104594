const store = {
  clear() {
    window.localStorage.clear();
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
  setItem(key, value) {
    window.localStorage.setItem(key, value);
  },
  getItem(key) {
    return window.localStorage.getItem(key);
  },
  getDisposableEmailCache() {
    const cache = this.getItem('disposableEmailCache');
    return cache ? JSON.parse(cache) : {};
  },
  setEmailValidation(email, validation) {
    const cache = this.getDisposableEmailCache();
    cache[email] = {
      disposable: validation.disposable,
      publicDomain: validation.publicDomain,
      timestamp: Date.now()
    };
    this.setItem('disposableEmailCache', JSON.stringify(cache));
  },
  isEmailCached(email) {
    const cache = this.getDisposableEmailCache();
    const entry = cache[email];
    
    if (!entry) return null;

    // Cache entries expire after 30 days
    const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
    if (Date.now() - entry.timestamp > THIRTY_DAYS) {
      // Remove expired entry
      delete cache[email];
      this.setItem('disposableEmailCache', JSON.stringify(cache));
      return null;
    }

    return {
      disposable: entry.disposable,
      publicDomain: entry.publicDomain
    };
  },
  markPurchaseMessageShown() {
    const key = 'lastSubscriptionPurchase';
    // Store the current month/year to track billing cycles
    const currentDate = new Date();
    const monthYear = `${currentDate.getMonth()}-${currentDate.getFullYear()}`;
    this.setItem(key, monthYear);
  },
  shouldShowPurchaseMessage() {
    const key = 'lastSubscriptionPurchase';
    const lastPurchase = this.getItem(key);
    
    if (!lastPurchase) return true;
    
    // Check if we're in a new month compared to last purchase
    const currentDate = new Date();
    const currentMonthYear = `${currentDate.getMonth()}-${currentDate.getFullYear()}`;
    
    return lastPurchase !== currentMonthYear;
  }
};

export default store;
