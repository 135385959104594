import { useState } from "react";
import { Link } from "react-router-dom";

import InfoCard from "./InfoCard";


export default function HomeHero({dashboard}) {
	const [copyButtonText, setCopyButtonText] = useState('Copy!')

	return (
		<>
			<h1 className="section-heading">Your Account</h1>
			<div className="w-full flex flex-wrap pt-3">
				<InfoCard
          passClass='flex justify-between items-end flex-auto flex-1' 
          name={'API key'}
          value={dashboard?.token} 
          action={(() => { 
          return (
          <button
          type='submit'
          className={`
            group
            relative 
            py-2 px-4 border h-10 text-sm font-medium rounded-md 
            hover:bg-gray-200 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mypurple
          `}
          onClick={() => {
            navigator.clipboard.writeText(dashboard?.token);
            setCopyButtonText('Copied!');
            setTimeout(() => {
              setCopyButtonText('Copy!');
            }, 5000);
          }}
          >
            {copyButtonText}
          </button>)
          })()}
          isBlurText={true}
				/>
				<InfoCard 
          passClass='flex justify-between items-end flex-auto flex-1' 
          name={'Plan Name'} 
          // value={`${dashboard.plan_name === 'Trial' ? 'None' : dashboard.plan_name}`}
          value={dashboard?.plan_name}
          action={(() => {
            return (
              <Link to='/billing' className='text-sm underline text-mypurple'>
                  Upgrade Plan
              </Link>
            )
          })()}
        />
				<InfoCard passClass='flex-auto flex-1' name={'Credits'} value={dashboard?.credits.amt} />
			</div>
		</>
	)
}