import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogPanel, DialogBackdrop } from '@headlessui/react';

function SearchModal({ open, setOpen, search, setSearch }) {
  const [unappliedSearch, setUnappliedSearch] = useState('');

  const handleApplySearch = () => {
    setSearch(unappliedSearch);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0 mt-5">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Search
              </DialogTitle>
              <div className="my-4">
                <label htmlFor="search" className="block text-sm font-medium text-gray-700">
                  Search across: platform, group name, url, search keyword
                </label>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={unappliedSearch || search || ''}
                  onChange={(e) => setUnappliedSearch(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleApplySearch}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-mypurple px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Apply Search
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default SearchModal;