import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import api from '../utils/api';
import logo from '../assets/images/logo.png';
import store from '../utils/store';

import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccessAlert';


export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDisabled(!email);
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsFormLoading(true);
      await api.forgotPasswordForm(email);
      setIsDone(true);
    } catch(e) {
      setErrorMsg(e.message.split('|'));
    } finally {
      setIsFormLoading(false);
    }
  }

  if (store.getItem('accessToken')) {
    return <Navigate to='/' />
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to='/'>
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Unwrangle Logo"
          />
        </Link>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-mypurple">
          Forgot your password?
        </h2>
        {!isDone && (
          <p className="mt-2 text-center text-sm text-gray-600">
            Submit your account email address for a reset link
          </p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {!isDone ? (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mypurple focus:border-mypurple sm:text-sm"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mypurple disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isDisabled || isFormLoading}
                >
                {isFormLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24" file="none">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  'Submit'
                )}
                </button>
              </div>
              {errorMsg && (
                <ErrorAlert msg={errorMsg} />
              )}
              <p className='text-sm text-gray-500'> <ExclamationCircleIcon className='inline h-4 w-4 text-gray-500' /> Please Note: Changing your password will also reset your API key.</p>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or back to
                  </span>
                </div>
              </div>
              <div className="mt-6 grid grid-cols-2 gap-3">
                <div>
                  <Link
                    to="/login"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Login
                  </Link>
                </div>
                <div>
                  <Link
                    to="/signup"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SuccessAlert msg='Check your email for a link to reset your password.' />
        )}
      </div>
    </div>
  )
}