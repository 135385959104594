import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DateTime from 'luxon/src/datetime.js'
import { CheckCircleIcon, XCircleIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronRightIcon as ChevronRightIconSolid, ArrowPathIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import emoji from 'emoji-dictionary';
import { format } from 'date-fns';
import * as Tooltip from '@radix-ui/react-tooltip';
import EditItemNameModal from './EditableItemNameModal';
import EditableItemName from './EditableItemName';

// Add classes to job status batch

const statusStyles = {
  complete: 'bg-green-100 text-green-800',
  queued: 'bg-yellow-100 text-yellow-800',
  running: 'bg-blue-100 text-blue-800',
  maintenance: 'bg-gray-100 text-gray-800',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function formatDistanceToNow(date) {
  const now = new Date();
  const diffInMs = date - now;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInWeeks = Math.floor(diffInDays / 7);

  if (diffInWeeks > 0) {
    return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}`;
  } else if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  } else {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  }
}

export default function ReviewJobs({
  jobs,
  setJobs,
  setExportJob,
  currentPage,
  setCurrentPage,
  jobsCount,
  totalPages,
  onRerunGroup,
}) {
  const [expandedGroups, setExpandedGroups] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);

  // Function to handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Generate pagination buttons dynamically with ellipsis for large page numbers
  const paginationButtons = [];
  const maxPageVisible = 4;
  const halfMaxPageVisible = Math.floor(maxPageVisible / 2);

  let startPage = Math.max(1, currentPage - halfMaxPageVisible);
  let endPage = Math.min(totalPages, currentPage + halfMaxPageVisible);

  if (currentPage - halfMaxPageVisible < 1) {
    endPage = Math.min(totalPages, endPage + (halfMaxPageVisible * 2 - (currentPage - 1)));
  } else if (currentPage + halfMaxPageVisible > totalPages) {
    startPage = Math.max(1, startPage - (halfMaxPageVisible * 2 - (totalPages - currentPage)));
  }

  if (startPage > 1) {
    paginationButtons.push(
      <button
        key="page1"
        onClick={() => handlePageChange(1)}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        1
      </button>
    );
    if (startPage > 2) {
      paginationButtons.push(
        <span key="ellipsis1" className="px-4 py-2 text-sm font-semibold">...</span>
      );
    }
  }

  for (let page = startPage; page <= endPage; page++) {
    paginationButtons.push(
      <button
        key={page}
        onClick={() => handlePageChange(page)}
        disabled={currentPage === page}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page ? 'text-white bg-mypurple' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} focus:z-20 focus:outline-offset-0`}
      >
        {page}
      </button>
    );
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      paginationButtons.push(
        <span key="ellipsis2" className="px-4 py-2 text-sm font-semibold">...</span>
      );
    }
    paginationButtons.push(
      <button
        key="pageLast"
        onClick={() => handlePageChange(totalPages)}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        {totalPages}
      </button>
    );
  }

  const toggleGroup = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  // Remember that jobs state is an array of jobs and batches
  const handleNameUpdate = (batchId, newName) => {
    setJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.group_id === batchId ? { ...job, group_name: newName } : job
      )
    );
  };

  const renderJobRow = (item, isSubJob = false, index, isLastSubJob = false) => {
    const isBatch = item.item_type === 'batch';
    const job = isSubJob ? item : (isBatch ? null : item.job_data);
    const isExpanded = isBatch && expandedGroups[item.item_id];

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'MM/dd/yy');
    };

    const getGroupStatus = (jobData) => {
      const totalJobs = jobData.length;
      const completedJobs = jobData.filter(job => job.status === 'complete').length;
      const pendingJobs = totalJobs - completedJobs;
      
      if (pendingJobs === 0) return 'All Complete';
      if (completedJobs === 0) return `${pendingJobs} Pending`;
      return `${completedJobs} Complete, ${pendingJobs} Pending`;
    };

    // New helper functions for batch rows
    const getBatchCreatedDate = (jobData) => {
      if (jobData.length > 0) {
        return formatDate(jobData[0].created);
      }
      return '';
    };

    const getBatchTotalCredits = (jobData) => {
      return jobData.reduce((total, job) => total + (job.credits_used || 0), 0);
    };

    const getBatchWebhookStatus = (jobData) => {
      if (jobData.length > 0) {
        return jobData[0].is_webhook_sent;
      }
      return false;
    };

    return (
      <tr key={item.item_id || item.id} className={`
        ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
        ${isBatch ? 'border-t border-b border-gray-200' : ''}
        ${isSubJob && isLastSubJob ? 'border-b border-gray-200' : ''}
        ${isExpanded ? 'h-8' : ''}
      `}>
        {isBatch ? (
          <>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm font-medium text-gray-900`}>
              <button 
                onClick={() => toggleGroup(item.item_id)} 
                className="p-1 rounded-full border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isExpanded ? (
                  <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                ) : (
                  <ChevronRightIconSolid className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              <EditableItemName
                name={item.group_name}
                onEdit={() => {
                  setSelectedBatch(item);
                  setIsEditModalOpen(true);
                }}
              />
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              {getBatchCreatedDate(item.job_data)}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-normal text-sm max-w-[300px]`}>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <span className={`${item.next_run ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'} px-2 inline-flex text-xs leading-5 font-semibold rounded-full cursor-default`}>
                      {item.next_run ? `Next run in ${formatDistanceToNow(new Date(item.next_run))}` : 'Not Scheduled'}
                    </span>
                  </Tooltip.Trigger>
                  {item.next_run && (
                    <Tooltip.Portal>
                      <Tooltip.Content className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                        {format(new Date(item.next_run), 'MMMM d, yyyy h:mm a')}
                        <Tooltip.Arrow className="fill-gray-800" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  )}
                </Tooltip.Root>
              </Tooltip.Provider>
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm`}>
              <span className={`${getGroupStatus(item.job_data).includes('Pending') ? 'bg-gray-100 text-gray-800' : 'bg-green-100 text-green-800'} px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                {getGroupStatus(item.job_data)}
              </span>
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-900 text-center`}>
              {getBatchTotalCredits(item.job_data)}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              {getBatchWebhookStatus(item.job_data) ? (
                <CheckCircleIcon className="h-5 w-5 text-green-400 mx-auto" aria-hidden="true" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-yellow-400 mx-auto" aria-hidden="true" />
              )}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-right text-sm font-medium`}>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button 
                      className='mr-2 text-mypurple hover:text-myorange'
                      onClick={() => onRerunGroup(item.group_id)}
                    >
                      <ArrowPathIcon className="h-5 w-5" />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                      Rerun Group
                      <Tooltip.Arrow className="fill-gray-800" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </td>
          </>
        ) : (
          <>
            <td className={`px-6 ${isSubJob ? 'pl-8' : ''} ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm font-medium text-gray-500`}>
              {item.item_id || job.id}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              {job.service_platform}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              <span title={DateTime.fromISO(item.created || job.created).toLocaleString(DateTime.DATETIME_MED)}>
                {formatDate(item.created || job.created)}
              </span>
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-normal text-sm max-w-[300px]`}>
              {job.urls && job.urls.length > 0 ? (
                <p className='text-mypurple hover:text-myorange'>{`${job.urls.length} URLs`}</p>
              ) : job.url ? (
                <a href={job.url} rel='noreferrer' target="_blank" className="text-mypurple hover:text-myorange block overflow-hidden text-ellipsis whitespace-nowrap text-smaller">
                  {job.url}
                </a>
              ) : (
                <p className='text-mypurple hover:text-myorange font-mono'>{`search: ${job.search}`}</p>
              )}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm`}>
              <span className={classNames(
                statusStyles[item.item_status || job.status],
                'px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize'
              )}>
                {item.item_status || job.status}
              </span>
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-900 text-center`}>
              {job.credits_used}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-sm text-gray-500`}>
              {job.is_webhook_sent ? (
                <CheckCircleIcon className="h-5 w-5 text-green-400 mx-auto" aria-hidden="true" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-yellow-400 mx-auto" aria-hidden="true" />
              )}
            </td>
            <td className={`px-6 ${isExpanded ? 'py-1' : 'py-4'} whitespace-nowrap text-right text-sm font-medium`}>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button 
                      className='mr-2 disabled:cursor-not-allowed'
                      onClick={(e) => {
                        e.preventDefault();
                        setExportJob(item.item_id || item.id);
                      }}
                      disabled={(item.item_status || job.status) !== 'complete'}
                    >
                      {emoji.getUnicode("outbox_tray")}
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content className="bg-gray-800 text-white px-2 py-1 rounded text-sm">
                      Export Job
                      <Tooltip.Arrow className="fill-gray-800" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </td>
          </>
        )}
      </tr>
    );
  };

  if (!jobs) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y-0">
              <thead className="bg-gray-50 border-b border-gray-200">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Id
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Query
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Credits
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Webhook
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Export</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {jobs.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <p className='p-3 pl-6 text-gray-500'>You haven't scraped any pages yet, use the button above to create your first job!</p>
                    </td>
                  </tr>
                )}
                {jobs.map((item, index) => (
                  <React.Fragment key={item.item_id}>
                    {renderJobRow(item, false, index)}
                    {item.item_type === 'batch' && expandedGroups[item.item_id] && (
                      <>
                        {item.job_data.map((subJob, subIndex) => renderJobRow(subJob, true, index + subIndex + 1, subIndex === item.job_data.length - 1))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="100%" className="bg-gray-50 px-4 py-3 border-t border-gray-200 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                        Total results: {jobsCount}. Showing page <span className="font-medium">{currentPage}</span> of <span className="font-medium">{totalPages}</span>
                        </p>
                      </div>
                      <div>
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                          {paginationButtons}
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </nav>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <EditItemNameModal
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        item={selectedBatch}
        itemType="batch"
        onNameUpdate={(newName) => {
          handleNameUpdate(selectedBatch.group_id, newName);
          setSelectedBatch(null);
        }}
      />
    </div>
  )
}