import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogPanel, DialogBackdrop } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import api from '../utils/api';
import store from '../utils/store';
import NotificationToast from './NotificationToast'; // Import NotificationToast

// group name can be changed via group or batch
export default function EditItemNameModal({ open, setOpen, item, itemType, onNameUpdate }) {
  const [newName, setNewName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // Add state for success notification
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (item) {
      const currentName = itemType === 'batch' ? item.group_name : item.name;
      setNewName(currentName || '');
    }
  }, [item, itemType]);

  const handleSave = async () => {
    try {
      const accessToken = store.getItem('accessToken');
      const groupId = itemType === 'batch' ? item.group_id : item.id;
      await api.updateGroupName(accessToken, groupId, newName);
      onNameUpdate(newName);

      // Set success message and show toast
      setSuccessMessage(`Group "${newName}" updated successfully.`);
      setShowSuccessToast(true);

      setOpen(false);

    } catch (error) {
      setErrorMsg(error.message || 'Failed to update name.');
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-mypurple sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Edit Group Name
                    </DialogTitle>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mypurple focus:ring-mypurple sm:text-sm"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                      />
                      {errorMsg && (
                        <p className="mt-2 text-sm text-red-600">{errorMsg}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleSave}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-mypurple px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mypurple sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mypurple sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Render the NotificationToast */}
      <NotificationToast
        show={showSuccessToast}
        setShow={setShowSuccessToast}
        notifMsg={successMessage}
        notifType="success"
      />
    </>
  );
}