/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from 'react'
import { DialogTitle, Dialog, DialogPanel, DialogBackdrop } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import store from '../utils/store';
import api from '../utils/api';
import { timeout, verifyToken } from '../utils/utils'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./shadcnui/Select"

// Import the platform icons
import amazonIcon from '../assets/images/scraper-icons/amazon-icon.png';
import bestbuyIcon from '../assets/images/scraper-icons/bestbuy-icon.png';
import costcoIcon from '../assets/images/scraper-icons/costco-icon.png';
import gmapsIcon from '../assets/images/scraper-icons/gmaps-icon.png';
import homedepotIcon from '../assets/images/scraper-icons/homedepot-icon.png';
import lowesIcon from '../assets/images/scraper-icons/lowes-icon.png';
import mercadoIcon from '../assets/images/scraper-icons/mercado-icon.png';
import samsclubIcon from '../assets/images/scraper-icons/samsclub-icon.png';
import targetIcon from '../assets/images/scraper-icons/target-icon.png';
import walmartIcon from '../assets/images/scraper-icons/walmart-icon.png';
import wayfairIcon from '../assets/images/scraper-icons/wayfair-icon.png';
import yelpIcon from '../assets/images/scraper-icons/yelp-icon.png';

const helpUrl = "mailto:support@unwrangle.com";

const isLoadingStyles = (b) => b ? (
  "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 animate-pulse"
) : (
  "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
)

const countryOptions = {
  'us': 'USA',
  'ca': 'Canada',
  'gb': 'UK',
  'fr': 'France',
  'de': 'Germany',
  'es': 'Spain',
  'jp': 'Japan',
  'au': 'Australia',
  'in': 'India',
  'br': 'Brazil',
};

export default function JobModal({open, setOpen}) {
  const [inputValue, setInputValue] = useState('');
  const [inputArray, setInputArray] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [jobsCreated, setJobsCreated] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [geoCoordinates, setGeoCoordinates] = useState('');
  const [zoom, setZoom] = useState('');
  const [placeId, setPlaceId] = useState([]); // Change to array
  const [groupName, setGroupName] = useState('');
  const [scheduleIntervalValue, setScheduleIntervalValue] = useState('');
  const [scheduleIntervalUnit, setScheduleIntervalUnit] = useState('');
  const [scheduleIntervalError, setScheduleIntervalError] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [location, setLocation] = useState('');
  const [externalId, setExternalId] = useState(''); // Add this line
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    // Reset form state when the modal opens
    if (open) {
      setInputValue('');
      setInputArray([]);
      setWebhookUrl('');
      setIsDisabled(true);
      setJobsCreated(0);
      setIsLoading(false);
      setErrorMessage('');
      setGeoCoordinates('');
      setZoom('');
      setPlaceId([]);
      setGroupName('');
      setScheduleIntervalValue('');
      setScheduleIntervalUnit('');
      setScheduleIntervalError('');
      setCountryCode('us');
      setLocation('');
      setExternalId(''); // Add this line
    }
  }, [open]);

  useEffect(() => {
    // Split and clean the input value to check if there are valid entries
    const entries = inputValue.split('\n').filter(line => line.trim() !== '');
    if (entries.length > 0 && entries.length <= 100 && selectedPlatform) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValue, selectedPlatform]);

  useEffect(() => {
    setScheduleIntervalValue('');
    setScheduleIntervalError('');
  }, [scheduleIntervalUnit]);

  useEffect(() => {
    const fetchPlatforms = async () => {
      const accessToken = store.getItem('accessToken');
      const result = await api.listServicePlatforms(accessToken);
      if (result.success) {
        setPlatforms(result.platforms);
        setSelectedPlatform(result.platforms[0]?.name || '');
      }
    };
    fetchPlatforms();
  }, []);

  const isSearchPlatform = selectedPlatform.endsWith('_search');
  const inputLabel = isSearchPlatform ? "Search Keywords (comma or newline separated)" : "URLs (comma or newline separated)";
  const placeholderText = isSearchPlatform ? "Enter search keywords here" : "https://www.mercadolivre.com.br/shampoo-anticaspa-men-ice-cool-menthol-400ml-clear/p/MLB24299741";

  const handleSubmit = async () => {
    // Disable the submit button immediately when handleSubmit is clicked
    setIsDisabled(true);

    // Directly compute inputArray from inputValue before proceeding
    const currentInputArray = inputValue.split('\n').filter(line => line.trim() !== '');

    try {
      await verifyToken();
      const accessToken = store.getItem('accessToken');
      const isSearchPlatform = selectedPlatform.endsWith('_search');
      const isDetailPlatform = selectedPlatform.endsWith('_detail');

      setIsLoading(true);

      // Validate scheduled interval
      if (scheduleIntervalValue && scheduleIntervalUnit) {
        const value = parseInt(scheduleIntervalValue);
        if (scheduleIntervalUnit === 'H' && (value < 1 || value > 23)) {
          setScheduleIntervalError('Interval must be between 1 and 23 hours');
          setIsLoading(false);
          setIsDisabled(false); // Re-enable submit button on error
          return;
        } else if (scheduleIntervalUnit === 'D' && (value < 1 || value > 6)) {
          setScheduleIntervalError('Interval must be between 1 and 6 days');
          setIsLoading(false);
          setIsDisabled(false); // Re-enable submit button on error
          return;
        } else if (scheduleIntervalUnit === 'W' && (value < 1 || value > 6)) {
          setScheduleIntervalError('Interval must be between 1 and 6 weeks');
          setIsLoading(false);
          setIsDisabled(false); // Re-enable submit button on error
          return;
        }
      }

      let res;

      // Prepare additional parameters based on the platform
      let additionalParams = {};
      if (selectedPlatform === 'gmaps_search') {
        additionalParams = {
          geo_coordinates: geoCoordinates,
          zoom: zoom
        };
      } else if (selectedPlatform === 'gmaps_reviews') {
        additionalParams = {
          place_ids: placeId // Already an array, no changes needed here
        };
      } else if (selectedPlatform === 'amazon_search') {
        additionalParams = {
          country_code: countryCode
        };
      } else if (selectedPlatform === 'yelp_search') {
        additionalParams = {
          location: location
        };
      }

      // Add group_name, scheduled_interval, and external_id to additionalParams
      if (groupName) {
        additionalParams.group_name = groupName;
      }
      if (scheduleIntervalValue && scheduleIntervalUnit) {
        if (scheduleIntervalUnit === 'H') {
          additionalParams.scheduled_interval = `${scheduleIntervalValue}:00:00`;
        } else if (scheduleIntervalUnit === 'D') {
          additionalParams.scheduled_interval = `${scheduleIntervalValue} 00:00:00`;
        } else if (scheduleIntervalUnit === 'W') {
          const days = scheduleIntervalValue * 7;
          additionalParams.scheduled_interval = `${days} 00:00:00`;
        }
      }
      if (externalId) {
        additionalParams.external_id = externalId; // Add this line
      }

      res = await api.createBulkReviewJobs(accessToken, selectedPlatform, currentInputArray, webhookUrl || undefined, isSearchPlatform, additionalParams);
      if (res.success) {
        setJobsCreated(currentInputArray.length);
      }

      setTimeout(() => {
        setIsLoading(false);
        setOpen(false);
        setInputValue('');
        setInputArray([]);
        setIsDisabled(true);
        setJobsCreated(0);
        setWebhookUrl('');
        setErrorMessage('');
      }, 500);
    } catch (e) {
      setErrorMessage(e.message);
      setIsLoading(false);
      setIsDisabled(false); // Re-enable submit button on error
      return;
    }
  };

  let platformGroups = {};
  
  const platformIcons = {
    amazon: amazonIcon,
    bestbuy: bestbuyIcon,
    costco: costcoIcon,
    gmaps: gmapsIcon,
    homedepot: homedepotIcon,
    lowes: lowesIcon,
    mercado: mercadoIcon,
    samsclub: samsclubIcon,
    target: targetIcon,
    walmart: walmartIcon,
    wayfair: wayfairIcon,
    yelp: yelpIcon,
  };

  platforms.forEach(platform => {
    const [platformName, platformType] = platform.name.split('_');
    if (!platformGroups[platformName]) {
      platformGroups[platformName] = [];
    }
    platformGroups[platformName].push(platform);
  });

  // Sort platformGroups alphabetically and sort platforms within each group in reverse alphabetical order
  platformGroups = Object.keys(platformGroups).sort().reduce((acc, key) => {
    acc[key] = platformGroups[key].sort((a, b) => b.name.localeCompare(a.name));
    return acc;
  }, {});

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0 mt-5">
          <DialogPanel
            transition
            className={isLoadingStyles(isLoading)}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Create New Jobs
                  </DialogTitle>
                  <div className="mt-3">
                    <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
                      Select Platform
                    </label>
                    <Select
                      id="platform"
                      value={selectedPlatform}
                      onValueChange={setSelectedPlatform}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a platform" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(platformGroups).map(([platformName, platforms]) => (
                          <SelectGroup key={platformName}>
                            <SelectLabel>
                              <div className="flex items-center">
                                <img src={platformIcons[platformName]} alt={platformName} className="h-5 w-5 mr-2" />
                                {platformName.charAt(0).toUpperCase() + platformName.slice(1)} Scrapers
                              </div>
                            </SelectLabel>
                            {platforms.map(platform => (
                              <SelectItem key={platform.name} value={platform.name}>
                                {platform.name.replace('_', ' ')}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <p className="text-gray-500 flex">
                      <InformationCircleIcon className='h-4 w-4 self-center mr-1' />{inputLabel}
                    </p>
                    <div className="mt-2">
                      <textarea
                        id="input"
                        name="input"
                        rows={3}
                        value={inputValue}
                        onChange={(e) => {
                          const rawInput = e.target.value;
                          if (selectedPlatform.endsWith('_search')) {
                            // For search platforms, split by new lines or commas, preserve spaces within keywords
                            const lines = rawInput.split(/\r?\n|,/);
                            const cleanedLines = lines.map(line => line); // Do not trim spaces within keywords
                            const cleanedInput = cleanedLines.join('\n'); // Join back with new lines
                            setInputValue(cleanedInput);
                          } else if (selectedPlatform === 'gmaps_reviews') {
                            // For 'gmaps_reviews', split by new lines only, trim, but preserve query parameters
                            const lines = rawInput.split(/\r?\n/);
                            const cleanedLines = lines.map(line => line.trim()); // Only trim whitespace, do not strip query parameters
                            const cleanedInput = cleanedLines.join('\n'); // Join back with new lines
                            setInputValue(cleanedInput);
                          } else if (selectedPlatform.endsWith('_category')) {
                            // For category platforms, do not modify the URLs
                            setInputValue(rawInput);
                          } else {
                            // For other URL inputs, split by new lines or commas, trim, and remove unnecessary parts
                            const lines = rawInput.split(/\r?\n|,/);
                            const cleanedLines = lines.map(line => {
                              const trimmedLine = line.trim();
                              const queryStartIndex = trimmedLine.indexOf('?');
                              const hashStartIndex = trimmedLine.indexOf('#');
                              if (queryStartIndex !== -1) {
                                return trimmedLine.substring(0, queryStartIndex);
                              } else if (hashStartIndex !== -1) {
                                return trimmedLine.substring(0, hashStartIndex);
                              }
                              return trimmedLine;
                            });
                            const cleanedInput = cleanedLines.join('\n'); // Join back with new lines
                            setInputValue(cleanedInput);
                          }
                        }}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder={placeholderText}
                      />
                      <p className="mt-2 text-sm text-gray-500">Each entry will create a separate job (max. 100 entries)</p>
                    </div>
                  </div>
                  {selectedPlatform === 'gmaps_search' && (
                    <div>
                      <label htmlFor="geoCoordinates" className="block text-sm font-medium text-gray-700 mt-3">
                        Geo Coordinates (optional)
                      </label>
                      <input
                        type="text"
                        id="geoCoordinates"
                        name="geoCoordinates"
                        placeholder='19.0760, 72.8777'
                        value={geoCoordinates}
                        onChange={(e) => setGeoCoordinates(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                      <label htmlFor="zoom" className="block text-sm font-medium text-gray-700 mt-3">
                        Zoom (1-21, optional)
                      </label>
                      <input
                        type="number"
                        id="zoom"
                        name="zoom"
                        value={zoom}
                        onChange={(e) => setZoom(e.target.value)}
                        min="1"
                        max="21"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                    </div>
                  )}
                  {selectedPlatform === 'gmaps_reviews' && (
                    <div>
                      <label htmlFor="placeId" className="block text-sm font-medium text-gray-700 mt-3">
                        Place IDs (required)
                      </label>
                      <textarea
                        id="placeId"
                        name="placeId"
                        rows={3}
                        value={placeId.join('\n')} // Change to join with new lines for consistency
                        onChange={(e) => {
                          const rawInput = e.target.value;
                          const placeIds = rawInput.split(/[\r\n]+/).map(id => id.trim()); // Split by new lines only
                          setPlaceId(placeIds);
                        }}
                        required
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Enter multiple Place IDs separated by new lines. Place IDs can be retrieved using <strong>gmaps_search</strong> scraper.
                      </p>
                    </div>
                  )}
                  {selectedPlatform === 'amazon_search' && (
                    <div className="mt-3">
                      <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700">
                        Country Code
                      </label>
                      <select
                        id="countryCode"
                        name="countryCode"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        {Object.entries(countryOptions).map(([code, name]) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {selectedPlatform === 'yelp_search' && (
                    <div className="mt-3">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder='San Francisco, CA, United States'
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                    </div>
                  )}
                  <div className="mt-3">
                    <label htmlFor="webhookUrl" className="block text-sm font-medium text-gray-700">
                      Webhook URL (optional)
                    </label>
                    <input
                      type="text"
                      id="webhookUrl"
                      name="webhookUrl"
                      value={webhookUrl}
                      onChange={(e) => setWebhookUrl(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="groupName" className="block text-sm font-medium text-gray-700">
                      Group Name (optional)
                    </label>
                    <input
                      type="text"
                      id="groupName"
                      name="groupName"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="scheduleInterval" className="block text-sm font-medium text-gray-700">
                      Schedule Interval (optional)
                    </label>
                    <div className="flex mb-3">
                      <select
                        id="scheduleIntervalUnit"
                        name="scheduleIntervalUnit"
                        value={scheduleIntervalUnit}
                        onChange={(e) => {
                          setScheduleIntervalUnit(e.target.value);
                          setScheduleIntervalError('');
                        }}
                        className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">Select Unit</option>
                        <option value="H">Hours</option>
                        <option value="D">Days</option>
                        <option value="W">Weeks</option>
                      </select>
                      <input
                        type="number"
                        id="scheduleIntervalValue"
                        name="scheduleIntervalValue"
                        value={scheduleIntervalValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          setScheduleIntervalValue(value);

                          // Validate the input based on the selected unit
                          if (scheduleIntervalUnit === 'H' && (value < 1 || value > 23)) {
                            setScheduleIntervalError('Interval must be between 1 and 23 hours');
                          } else if (scheduleIntervalUnit === 'D' && (value < 1 || value > 6)) {
                            setScheduleIntervalError('Interval must be between 1 and 6 days');
                          } else if (scheduleIntervalUnit === 'W' && (value < 1 || value > 6)) {
                            setScheduleIntervalError('Interval must be between 1 and 6 weeks');
                          } else {
                            setScheduleIntervalError('');
                          }
                        }}
                        min={1}
                        max={scheduleIntervalUnit === 'H' ? 23 : scheduleIntervalUnit === 'D' ? 6 : 6}
                        className="mt-1 block w-24 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ml-2"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">Schedule jobs to run on regular intervals</p>
                    {scheduleIntervalError && (
                      <p className="mt-2 text-sm text-red-600">{scheduleIntervalError}</p>
                    )}
                  </div>
                  <div className="mt-3">
                    <label htmlFor="externalId" className="block text-sm font-medium text-gray-700">
                      External ID (optional)
                    </label>
                    <input
                      type="text"
                      id="externalId"
                      name="externalId"
                      value={externalId}
                      onChange={(e) => setExternalId(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Any id or string you wish to attach to each individual job (will be available in the webhook payload)
                    </p>
                  </div>
                  {errorMessage && (
                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-yellow-700">
                            {errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={isDisabled}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-mypurple px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed sm:ml-3 sm:w-auto sm:text-sm"
              >
                {isLoading ? 'Sending' : 'Submit'}
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-mypurple focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                ref={cancelButtonRef}
              >
                Cancel
              </button>
              <span className="inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-800 sm:ml-3">
                {`${jobsCreated} jobs created`}
              </span>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
