import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../components/shadcnui/Button';
import ScraperHero from '../components/ScraperHero';
import ReviewJobs from '../components/ReviewJobs';
import ReviewGroups from '../components/ReviewGroups'; // Add new component for Groups view
import Skeleton from '../components/Skeleton';
import LoadingPage from './LoadingPage';
import JobModal from '../components/JobModal';
import ExportModal from '../components/ExportModal';
import RerunDialog from '../components/RerunDialog';
import { Squares2X2Icon, AdjustmentsHorizontalIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import NotificationToast from '../components/NotificationToast'; // Import the NotificationToast component
import FiltersModal from '../components/FiltersModal';
import SearchModal from '../components/SearchModal';


import api from '../utils/api';
import store from '../utils/store';
import analytics from '../utils/analytics';

export default function Scrapers() {
  const { view: viewParam } = useParams();
  const [view, setView] = useState(viewParam || 'batches');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [jobs, setJobs] = useState([]);
  const [jobsCount, setJobsCount] = useState(0);
  const [groups, setGroups] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [isRerunDialogOpen, setIsRerunDialogOpen] = useState(false);
  const [selectJob, setSelectJob] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const [showErrorToast, setShowErrorToast] = useState(false); // Add state for showing/hiding the error toast
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: null,
    to: null,
  });
  const [search, setSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  async function listData(page=1, platform=null, dateRangeAfter=null, dateRangeBefore=null, search=null, status=null) {
    const accessToken = store.getItem('accessToken');

    if (!accessToken) {
      setErrorMsg('No access token found');
      setShowErrorToast(true);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const dashboard = await api.retrieveDashboardData(accessToken);
      setDashboard(dashboard);

      const identifier = store.getItem('identifier');

      let jobsData;
      let groupsData;

      // Convert dateRange values to ISO format YYYY-MM-DD
      const dateRangeAfterISO = dateRangeAfter ? new Date(dateRangeAfter).toISOString().split('T')[0] : null;
      const dateRangeBeforeISO = dateRangeBefore ? new Date(dateRangeBefore).toISOString().split('T')[0] : null;

      if (view === 'batches') {
        jobsData = await api.listBatchesAndJobs(accessToken, page, platform, dateRangeAfterISO, dateRangeBeforeISO, search, status);
        setJobs(jobsData.results);
        setJobsCount(jobsData.count);
      } else if (view === 'jobs') {
        jobsData = await api.listJobs(accessToken, page, platform, dateRangeAfterISO, dateRangeBeforeISO, search, status);
        setJobs(jobsData.results);
        setJobsCount(jobsData.count);
      } else if (view === 'groups') {
        groupsData = await api.listGroups(accessToken);
        setGroups(groupsData);
      }

      if (identifier) {
        analytics.identify(identifier);

        analytics.track(`View ${view}`, {
          'num_jobs': jobsData?.count || undefined,
          'credits': dashboard?.credits.amt
        });
        analytics.people.set({
          'plan': dashboard?.plan_name,
        });
      }
      if (view === 'batches' || view === 'jobs') {
        setTotalPages(Math.ceil(jobsData?.count / 10));
      }
    } catch(e) {
      setErrorMsg(e.message);
      setShowErrorToast(true);
      setDashboard(null); // Ensure dashboard is cleared on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleRerunGroup = async () => {
    if (selectedGroupId) {
      try {
        const accessToken = store.getItem('accessToken');
        await api.rerunGroup(accessToken, selectedGroupId);
        setIsRerunDialogOpen(false);
        // Refresh the job list after rerunning the group
        listData(currentPage);
      } catch (error) {
        console.error('Error rerunning group:', error);
        setErrorMsg('Failed to rerun the group. Please try again.');
      }
    }
  };

  const clearFilters = async () => {
    setSelectedPlatform(null);
    setSelectedDateRange({
      from: null,
      to: null,
    });
    setSelectedStatus(null);
    setSearch('');
  };

// useEffect(() => {
  //   const path = location.pathname;
  //   if (path === '/scrapers/jobs') {
  //     setView('jobs');
  //   } else if (path === '/scrapers/groups') {
  //     setView('groups');
  //   } else {
  //     setView('batches');
  //   }
  // }, [location]);

  useEffect(() => {
    const fetchPlatforms = async () => {
      const accessToken = store.getItem('accessToken');
      const result = await api.listServicePlatforms(accessToken);
      if (result.success) {
        setPlatforms(result.platforms);
      }
    };
    fetchPlatforms();
  }, []);

  useEffect(() => {
    listData(currentPage, selectedPlatform, selectedDateRange.from, selectedDateRange.to, search, selectedStatus);
  }, [view, currentPage, selectedPlatform, selectedDateRange, search, selectedStatus]);

  useEffect(() => {
    return function cleanup() {
      setJobs([]);
      setGroups([]);
    };
  }, []);

  useEffect(() => {
    if ((view === 'batches' && location.pathname !== '/scrapers') || (view !== 'batches' && location.pathname !== `/scrapers/${view}`)) {
      if (view === 'batches') {
        navigate('/scrapers');
      } else {
        navigate(`/scrapers/${view}`);
      }
    }
  }, [view, navigate]);

  useEffect(() => {
    if (!isOpen || !isRerunDialogOpen) {
      listData(currentPage, selectedPlatform, selectedDateRange.from, selectedDateRange.to, search, selectedStatus);
    }
  }, [isOpen, isRerunDialogOpen])

  useEffect(() => {
    if (selectJob) {
      setIsExportOpen(true);
    }
  }, [selectJob])

  const clearSearch = () => {
    setSearch('');
  };

  const handleGroupNameUpdate = (groupId, newName) => {
    setJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.group_id === groupId
          ? { ...job, group_name: newName }
          : job
      )
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Skeleton dashboard={dashboard}>
      {errorMsg && (
        <NotificationToast 
          show={showErrorToast} 
          setShow={setShowErrorToast}
          notifMsg={errorMsg}
          notifType="error"
        />
      )}
      {dashboard && (  // Only show content if dashboard exists
        <>
          <JobModal open={isOpen} setOpen={(v) => { setIsOpen(v); }} />
          <ScraperHero dashboard={dashboard} toggleOpen={() => { setIsOpen(!isOpen); }} />
          <div className="flex items-center ml-2 mb-4">
            <Squares2X2Icon className="h-4 w-4 text-gray-500 mr-1" />
            <span className="mr-1 text-sm text-gray-500">Views:</span>
            <button
              className={`mr-2 text-sm ${
                view === 'jobs' ? 'text-mypurple' : 'text-gray-500'
              } hover:text-myorange`}
              onClick={() => setView('jobs')}
            >
              Jobs
            </button>
            <span className="mr-2 text-sm text-gray-500">|</span>
            <button
              className={`mr-2 text-sm ${
                view === 'batches' ? 'text-mypurple' : 'text-gray-500'
              } hover:text-myorange`}
              onClick={() => setView('batches')}
            >
              Batches
            </button>
            <span className="mr-2 text-sm text-gray-500">|</span>
            <button
              className={`mr-4 text-sm ${
                view === 'groups' ? 'text-mypurple' : 'text-gray-500'
              } hover:text-myorange`}
              onClick={() => setView('groups')}
            >
              Groups
            </button>
            <button 
              className="flex items-center mr-4 text-sm text-gray-500 hover:text-myorange"
              onClick={() => setIsFiltersOpen(true)}
            >
              <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1" />
              Filters
            </button>
            <button 
              className="flex items-center mr-4 text-sm text-gray-500 hover:text-myorange"
              onClick={() => setIsSearchOpen(true)}
            >
              <MagnifyingGlassIcon className="h-4 w-4 mr-1" />
              Search
            </button>
            {(selectedPlatform !== null || selectedDateRange.from !== null || selectedDateRange.to !== null || selectedStatus !== null) && (
              <Button variant="outline" className="border-dashed h-8 text-sm text-gray-500 bg-accent hover:text-myorange" onClick={clearFilters}>
                <XCircleIcon className="h-4 w-4 mr-2" />
                Clear Filters
              </Button>
            )}
            {search !== '' && (
              <Button variant="outline" className="border-dashed h-8 text-sm text-gray-500 bg-accent hover:text-myorange" onClick={clearSearch}>
                <XCircleIcon className="h-4 w-4 mr-2" />
                Clear Search
              </Button>
            )}
          </div>
          {view === 'batches' && (
            <ReviewJobs
              jobs={jobs}
              setJobs={setJobs}
              setExportJob={(v) => {
                setSelectJob(v);
                setIsExportOpen(true);
              }}
              currentPage={currentPage}
              setCurrentPage={(v) => setCurrentPage(v)}
              jobsCount={jobsCount}
              totalPages={totalPages}
              onRerunGroup={(groupId) => {
                setSelectedGroupId(groupId);
                setIsRerunDialogOpen(true);
              }}
              onGroupNameUpdate={handleGroupNameUpdate}
            />
          )}
          {view === 'jobs' && (
            <ReviewJobs
              jobs={jobs}
              setExportJob={(v) => {
                setSelectJob(v);
                setIsExportOpen(true);
              }}
              currentPage={currentPage}
              setCurrentPage={(v) => setCurrentPage(v)}
              totalPages={totalPages}
              onRerunGroup={(groupId) => {
                setSelectedGroupId(groupId);
                setIsRerunDialogOpen(true);
              }}
            />
          )}
          {view === 'groups' && <ReviewGroups groups={groups} setGroups={setGroups} />}
          <ExportModal open={isExportOpen} setOpen={(v) => { setIsExportOpen(v); }} jobId={selectJob} />
          {view === 'batches' && <RerunDialog 
            open={isRerunDialogOpen} 
            setOpen={setIsRerunDialogOpen} 
            jobGroup={jobs.find(job => job.group_id === selectedGroupId)}
            onConfirm={handleRerunGroup}
          />}
          <FiltersModal 
            open={isFiltersOpen} 
            setOpen={setIsFiltersOpen}
            platforms={platforms}
            selectedPlatform={selectedPlatform}
            setSelectedPlatform={setSelectedPlatform}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
          <SearchModal
            open={isSearchOpen}
            setOpen={setIsSearchOpen}
            search={search}
            setSearch={setSearch}
          />
        </>
      )}
    </Skeleton>
  );
}
